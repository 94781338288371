/* General styles - fonts etc */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600&display=swap');

/* Define key colours */
:root {
    --red: rgb(248, 84, 84);
    --orange: rgb(255, 123, 80);
    --ylw: #FFAD50;
    --grn: rgb(34, 120, 85);
    --grnFaded: rgb(34, 120, 85, 0.75);
    --txt: rgb(24, 24, 36);
    --txtFaded: rgb(124, 124, 146);
    --txtAdmin: rgb(122, 134, 161);
    --txtWhite: #fff;
    --txtWhiteFaded: rgba(255, 255, 255, 0.8);
    --bgGrey: rgb(240, 240, 245);
    --bgDarkerBlueGrey: rgb(220,220,232);
    --bgLightGrey: rgb(248, 248, 252);
    --bgDarkGrey: #121212;
    --bgBlueGrey: rgb(245, 246, 252);
    --borderGrey: rgb(230, 230, 230);
    --borderDarkGrey: rgb(130,130,135);
    --shadow: rgba(0,0,0,0.05);
}

body {
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    line-height: 1.8em;
    color: #000;
    font-weight: 300;
    background: #fff;
}
.whole {
    height: 100vh;
    width: 100%;
}
.whole.textured {
    background: var(--bgGrey) url('./../img/bpca/login-bg.png') 0% 0% / cover no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto
}

h1, h2, h3, h4 { font-family: "Jost", sans-serif; letter-spacing: 1px; font-weight: 500; }

h1 { font-size: 24px; font-weight: 500; line-height: 40px; margin-bottom: 22px; }
h2 { font-size: 22px; line-height: 32px; margin-bottom: 24px; }
h3 { font-size: 32px; line-height: 56px; margin-bottom: 0; }
h4 { font-size: 18px; line-height: 24px; padding-bottom: 8px; margin: 0 0 18px 0; font-weight: 500; position: relative; }

h4:after {
    content: "";
    width: 55px;
    height: 2px;
    background-color: #448575;
    position: absolute;
    bottom: 0;
    left: 0;
}

p { font-size: 19px; margin-bottom: 28px; }
p.sml { font-size: 15px; opacity: 0.8; }

a {  color: var(--grn); text-decoration: none; padding-bottom: 1px; border-bottom: 1px solid var(--grn); }
a.sml {  font-size: 14px; line-height: 16px; }
a:hover { border-bottom-color: rgba(0,0,0,0); }

a.btn, a.action-btn, button {
    display: inline-block;
    padding: 14px 52px;
    background: var(--grn);
    border-radius: 5px;
    border: none;
    text-decoration: none;
    color: var(--txtWhite);
    font-family: "Jost", sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 16px;
    margin-top: 24px;
    transition: 0.5s;
    cursor: pointer;
}
button {
    padding: 12px 42px;
    margin-bottom: 8px;
}
button.sml-btn {
    padding: 12px 25px;
}
a.btn:hover, button:hover {
    background: var(--grnFaded);
}

a.btn.curved {
    border-radius: 50px;
    font-weight: 300;
    padding: 8px 22px;
    font-size: 14px;
    margin-top: 15px;
    line-height: 1.5em;
}

a.action-btn {
    font-size: 16px;
    font-weight: 400;
    padding: 6px 15px;
    margin: 0 5px;
    transition: 0.5s;
}

.admin-container .content.core-admin a.action-btn.red {
    background: var(--red);
}

.admin-container .content.core-admin a.action-btn:hover {
    opacity: 0.5;
}

.input.checkbox input {
    float: left;
    margin-top: 1px;
    margin-right: 8px;
}
.val-msg {
    font-size: 14px;
    color: #BE2839;
    margin-top: 5px;
}

.faded {
    opacity: 0.5;
    font-style: italic;
}

.mbtm { margin-bottom: 22px; }
.right { float: right; }

/* Generic form styles */
label {
    display: block;
    opacity: 0.5;
    margin: 22px 0 5px 0;
    line-height: 18px;
    font-size: 16px;
}
input[type="text"], input[type="email"], input[type="password"], select {
    border: 1px solid var(--borderGrey);
    border-radius: 5px;
    padding: 12px 25px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    font-size: 20px;
    font-family: "Jost", arial, sans-serif;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus {
    outline: none;
    border-width: 3px;
    padding: 10px 22px;
}

.pseudo-table .pseudo-row {
    overflow: auto;
    padding: 18px 0;
}
.pseudo-table .pseudo-row + .pseudo-row {
    border-top: 1px solid var(--borderGrey);
}
.pseudo-table img{
    float: left;
    width: 32px;
    margin-right: 2%;
}
.pseudo-table .cell {
    width: 23%;
    float: left;
}
.pseudo-table .cell p, .pseudo-table .cell ul li {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    padding-left: 14px;
}
.pseudo-table .cell p strong {
    font-weight: 500;
}
.pseudo-table .cell ul {
    margin: 8px 0;
    list-style: none;
}
.pseudo-table .cell ul li:before {
    background-color: var(--grn);
    position: absolute;
    left: 0;
    top: 8px;
    content: " ";
    width: 4px;
    height: 4px;
    border-radius: 4px;
    display: block;
}
.pseudo-table .cell button {
    cursor: default;
    border-radius: 32px;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    float: right;
}
.pseudo-table .cell.failed button {
    background: var(--orange);
}

/* Messages and alerts */
.flash-msg {
    border-radius: 5px;
    background: #EF7B88;
    padding: 12px 18px;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 22px;
}
.flash-msg.grn {
    background: #95CFC0;
}

.flash-msg.question-msg{
    position: absolute;
    top: 185px;
}
.flash-msg p {
    margin-bottom: 0 !important;
}

/* Header / footer */
header {
    padding: 32px 0;
    width: 100%;
    z-index: 1;
}
header .logo {
    width: 100%;
    max-width: 225px;
}
header nav {
    text-align: right;
    padding: 16px 0;
}
header nav ul {
    list-style: none;
    display:block;
}
header nav ul li {
    display: inline-block;
    margin: 0 0 0 20px;
}
header nav a {
    color: #000;
    text-decoration: none;
    border-bottom: none;
    font-size: 14px;
    transition: 0.5s;
    padding: 10px 22px;
}
header nav a:hover {
    opacity: 0.5;
}

header.sidebar {
    width: 280px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--bgBlueGrey);
    padding: 32px;
    box-sizing: border-box;
}
.logo-sxn {
    margin-bottom: 44px;
}
.logo-sxn.left { float: left }
.logo-sxn img, .assessment .assessment-header .logo-sxn img {
    width: 64px;
    float: left;
}
.logo-sxn h2 {
    font-size: 21px;
    padding: 16px 0 0 72px;
    letter-spacing: 0.5px;
    opacity: 0.8;
    margin: 0;
}
header.sidebar nav {
    text-align: left;
}

header.sidebar nav ul {
    list-style: none;
    padding-left: 0;
}
header.sidebar nav ul li {
    margin: 6px 0;
    width: 100%;
}
header.sidebar nav ul li:last-child {
    margin-bottom: 0;
}
header.sidebar nav ul li.current, header.sidebar nav ul li:hover {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    padding: 0;
}
header.sidebar nav ul li a {
    color: var(--txtAdmin);
    background: url('./../img/bpca/logout-icon.svg') left 12px center / 22px auto no-repeat;
    display: block;
    font-size: 14px;
    padding: 8px 8px 8px 42px;
}
header.sidebar nav ul li a#dashboardLnk { background-image: url('./../img/bpca/dashboard.svg'); }
header.sidebar nav ul li a#usersLnk { background-image: url('./../img/bpca/employees.svg'); }
header.sidebar nav ul li a#clientsLnk { background-image: url('./../img/bpca/companies.svg'); }
header.sidebar nav ul li a#assessmentLnk { background-image: url('./../img/bpca/test-icon.svg'); background-size: 16px auto; background-position: left 14px center; }
header.sidebar nav ul li.current a, header.sidebar nav ul li a:hover {
    color: var(--grn);
    opacity: 1;
    background-image: url('./../img/bpca/logout-icon-grn.svg');
}
header.sidebar nav ul li.current a#dashboardLnk, header.sidebar nav ul li a#dashboardLnk:hover { background-image: url('./../img/bpca/dashboard-grn.svg'); }
header.sidebar nav ul li.current a#usersLnk, header.sidebar nav ul li a#usersLnk:hover { background-image: url('./../img/bpca/employees-grn.svg'); }
header.sidebar nav ul li.current a#clientsLnk, header.sidebar nav ul li a#clientsLnk:hover { background-image: url('./../img/bpca/companies-grn.svg'); }
header.sidebar nav ul li.current a#assessmentLnk, header.sidebar nav ul li a#assessmentLnk:hover { background-image: url('./../img/bpca/test-icon-grn.svg'); }

header.sidebar nav ul.btm {
    bottom: 32px;
    position: absolute;
}

footer {
    padding: 42px 0;
    text-align: center;
    clear: both;
}
footer img.logo {
    width: 160px;
    display: block;
    margin: 0 auto 24px auto;
}
footer p {
    margin: 0;
    font-size: 14px;
    font-family: "Gilroy-Light", arial, sans-serif;
    opacity: 0.5;
}
footer p.powered {
    opacity: 0.5;
    font-variant: small-caps;
    margin-bottom: 5px;
}

/* Questionnaire intro */
.intro .width-6 {
    margin: -42px auto 32px auto;
    display: block;
    text-align: center;
}
.intro .width-6 h1 {
    margin-bottom: 0;
}
.intro ol {
    text-align: left;
    margin-left: 42px;
}
.steps {
    width: 96%;
    max-width: 1080px;
    min-height: 48vh;
    overflow: auto;
    margin: 32px auto;
}
.steps .step {
    width: 30%;
    min-height: 385px;
    margin: 0;
    float: left;
    background: #fff;
    border-radius: 1px 1px 15px var(--shadow);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0;
    position: relative;
}
.steps .step .head-img {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    background: url('./../img/bpca/intro-kitchen.jpg') center center / cover no-repeat;
}
.steps .step .head-img.real {
    background-image: url('./../img/bpca/intro-commercial.png');
}
.steps .step .head-img.real .step-status {
    background: rgba(248,84,84,0.5);
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 48px 32px;
    font-weight: 500;
    text-shadow: 0 0 2px rgba(0,0,0,0.5);
}
.steps .step.pass .head-img.real .step-status {
    background: rgba(34,120,85,0.5);
}
.steps .step .head-img.video {
    background-image: url('./../img/bpca/login-sidebar-bg.png');
}
.steps .step + .step {
    margin-left: 5%;
}
.steps .step .wording {
    padding: 22px 32px;
}
.steps .step .wording h3 {
    font-size: 19px;
    line-height: 28px;
}
.steps .step .wording p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
}
.steps .step a.cover-btn {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    font-size: 0;
}
.steps .step a.onward-btn {
    cursor: pointer;
    width: 52px;
    height: 52px;
    position: absolute;
    top: 172px;
    right: 32px;
    border: none;
    border-radius: 40px;
    font-size: 0;
    background: var(--grn) url('./../img/bpca/arrow.svg') center center / 18px auto no-repeat;
}
.steps .step.submitted.pass a.onward-btn, .steps .step.practice.submitted a.onward-btn {
    background-image: url('./../img/bpca/tick-white.svg');
}
.steps .step.submitted.fail a.onward-btn {
    background-image: url('./../img/bpca/cross-white.svg');
    background-color: var(--red);
}
.steps .step.video a.onward-btn {
    top: 80px;
    left: 50%;
    margin-left: -26px;
    background-image: url('./../img/bpca/play-icon.svg');
    background-position: left 19px center;
}
.steps .step.faded a.cover-btn {
    cursor: default;
}
.steps .step.faded a.onward-btn {
    background-color: rgb(160,160,180);
}

/* Assessment screens */
.assessment.instructions {
    width: 96%;
    max-width: 1080px;
    min-height: 74vh;
    overflow: auto;
    margin: 0 auto 52px auto;
}
.assessment.instructions .left {
    float: left;
}
.assessment img {
    width: 100%;
    border-radius: 12px;
}
.assessment .icon-instruction img {
    margin-top: 5px;
    height: 42px;
    width: auto;
    float: left;
}
.assessment .icon-instruction p {
    margin-left: 68px;
}
.assessment.instructions ul {
    margin: 0 0 22px 32px;
    list-style: none;
}
.assessment.instructions ul li {
    position: relative;
    padding-left: 18px;
}
.assessment.instructions ul li:before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: var(--grn);
    position: absolute;
    left: 0;
    top: 14px;
}

/* Results screen + questionnaire sections */
ul.report-links {list-style: none;}
ul.report-links li { margin: 12px 0; }

.report-content h1 { margin: 0; }
.report-content h2 { opacity: 0.5; margin: 0 0 22px 0; font-size: 20px; }

.pin-show {
    width: 100%;
}
.pin-show button {
    width: 56px;
    height: 56px;
    border-radius: 50px;
    margin-top: 240px;
    padding: 0;
    text-align: center;
    float: left;
}
.pin-show button.nxt {
    float: right;
}
.pin-show .img {
    width: 90%;
    max-width: 800px;
    margin: 22px;
    padding: 5px;
    float: left;
    position: relative;
}
.pin-show .img img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
}
.rslt-pin {
    width: 48px;
    height: 62px;
    position: absolute;
    background: url('./../img/bpca/empty-pin-ylw.png') center center / contain no-repeat;
}
.rslt-pin.major {
    background-image: url('./../img/bpca/empty-pin-red.png');
}
.rslt-pin img.rslt-pin-icon {
    max-height: 20px;
    width: auto;
    margin: 14px auto;
    display: block;
}
.rslt-pin img.rslt-pin-icon.bristles, .rslt-pin img.rslt-pin-icon.rat-trap-uncovered {
    max-width: 26px;
    margin-top: 18px;
}
.rslt-pin img.rslt-pin-icon.rat-trap-uncovered { margin-top: 18px; }
.rslt-pin img.rslt-pin-icon.plastic-mesh,
.rslt-pin img.rslt-pin-icon.chicken-wire { margin-top: 15px; }

.rslt-pod {
    display: inline-block;
    padding: 2px 15px;
    background: var(--ylw);
    border-radius: 22px;
    color: var(--txtWhite);
    font-size: 13px;
}
.rslt-pod {
    padding: 5px 32px;
    font-size: 15px;
}
.rslt-pod.pass { background: var(--grn); }
.rslt-pod.fail { background: var(--red); }

ul.error-list { margin-bottom: 42px; margin-left: 22px;}

/* Login screens */
.login {
    margin: 0;
    padding: 0;
}
.login .sidebar {
    width: 420px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    float: left;
    z-index: 1;
    background: var(--bgDarkGrey) url('./../img/bpca/login-sidebar-bg.png') 0% 0% / cover no-repeat;
}

.login .sidebar img#bpca-logo {
    max-width: 200px;
    position: absolute;
    top: 50%;
    margin-top: -120px;
    left: 30px;
}
.login .sidebar img#ta-logo {
    max-width: 99px;
    margin: 30px;
}

.login .sidebar h1 {
    font-size: 27px;
    color: var(--txtWhite);
    font-weight: 300;
    position: absolute;
    left: 30px;
    bottom: 42px;
    margin: 0;
    line-height: 32px;
}
.login .sidebar h1 span {
    color: var(--orange);
    font-weight: 600;
}
.login .sidebar p {
    font-size: 12px;
    color: var(--txtWhiteFaded);
    font-weight: 300;
    position: absolute;
    left: 30px;
    bottom: 18px;
    margin: 0;
}

.login .core {
    background: var(--bgGrey) url('./../img/bpca/login-bg.png') top 0 left 420px / auto 100% no-repeat;
    padding: 0 0 0 420px;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    position: fixed;
    box-sizing: border-box;
    z-index: 0;
}
.login-content {
    background: none;
    width: 320px;
    margin: 200px auto;
}
.login-content h2 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
}

/* Admin containers */
.admin-container {
    padding: 42px 2px 42px 275px;
    width: 100%;
    min-height: 90vh;
    box-sizing: border-box;
}
.admin-container .admin-head {
    padding-top: 140px;
    position: relative;
    margin-bottom: 60px;
    overflow: hidden;
}
.admin-container .admin-head:before {
    background: linear-gradient(#B4DFD4, #A4D7C9, #95CFC0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    content: "";
}
.admin-container .admin-head .shard-one {
    content: "";
    background: #AADACE;
    width: 150%;
    height: 300px;
    position: absolute;
    bottom: -100%;
    right: -30%;
    transform: rotate(-8deg);
    z-index: -3;
    opacity: 0.4;
}
.admin-container .admin-head:after {
    content: "";
    background: #83C7B5;
    width: 150%;
    height: 300px;
    position: absolute;
    bottom: -100%;
    left: -40%;
    transform: rotate(10deg);
    z-index: -4;
    opacity: 0.4;
}
.admin-container nav.admin-nav {
    text-align: center;
    margin-bottom: 42px;
}
.admin-container nav.admin-nav ul {
    list-style: none;
}
.admin-container nav.admin-nav ul li {
    display: inline-block;
    margin: 0 20px;
}
.admin-container nav.admin-nav ul li a {
    display: block;
    padding: 5px 18px;
    border-bottom: none;
    font-size: 16px;
    transition: 0.5s;
}
.admin-container nav.admin-nav ul li a:hover {
    opacity: 0.5;
}
.admin-container .content.core-admin {
    min-height: 55vh;
}

.admin-container .content.core-admin .btn.minor {
    float: right;
    font-size: 16px;
    padding: 5px 22px;
    margin-top: 0;
}
.admin-container .content.core-admin .btn.minor + .btn.minor {
    margin-right: 22px;
}

.admin-container .content.core-admin .table-wrap {
    padding: 12px 22px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    margin: 22px 0;

    overflow: auto;
    max-height: 600px;
}
.admin-container .content.core-admin table {
    border-collapse: collapse;
    width: 100%;
}
.admin-container .content.core-admin table th, .admin-container .content.core-admin table td {
    padding: 12px 8px;
    border: none;
    margin: 0;
}
.admin-container .content.core-admin table th {
    text-align: left;
    font-weight: 500;
    border-bottom: 1px solid rgba(235,235,235);
}
.admin-container .content.core-admin table tbody tr:hover {
    box-shadow: 1px 1px 15px rgba(0,0,0,0.05);
}
.admin-container .content.core-admin table td {
    font-size: 15px;
    color: var(--txtAdmin);
}

.admin-container .content.core-admin table label {
    display: none;
}
.admin-container .content.core-admin table input {
    font-size: 16px;
    margin: 0;
}
.admin-container .content.core-admin table button {
    margin: 0 25px 0 0;
    padding: 12px 25px;
    text-transform: none;
    font-size: 16px;
}

.admin-container .content.core-admin .permissions {
    background: var(--bgLightGrey);
    margin: 22px 0 0 0;
    padding: 15px 22px;
}
.admin-container .content.core-admin .permissions h6 {
    font-size: 18px;
    margin: 0 0 18px 0;
    font-weight: 500;
}

.highlights {
    overflow: auto;
}
.highlights .thirds {
    background: linear-gradient(rgba(0, 175, 145, 0.12) 0%, rgba(242, 242, 242, 0) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    width: 32%;
    min-height: 320px;
    float: left;
    padding: 30px;
    margin: 0 2% 42px 0;
    border-radius: 12px;
    box-sizing: border-box;
    text-align: center;
}
.highlights .thirds.sml {
    min-height: 150px;
}
.highlights .thirds.users, .highlights .thirds.amber { background: linear-gradient(rgba(255, 207, 87, 0.2) 0%, rgba(255, 207, 87, 0) 100%) 0% 0% no-repeat padding-box padding-box transparent; }
.highlights .thirds.assessments { background: linear-gradient(rgba(109, 117, 255, 0.12) 0%, rgba(109, 117, 255, 0) 100%) 0% 0% no-repeat padding-box padding-box transparent; }
.highlights .thirds.red { background: linear-gradient(rgba(248, 84, 84, 0.12) 0%, rgba(248, 84, 84, 0) 100%) 0% 0% no-repeat padding-box padding-box transparent; }
.highlights .thirds:last-child {
    margin-right: 0;
}

.highlights .thirds h6 {
    font-size: 24px;
    font-weight: 500;
    margin: 12px 0 0 0;
}
.highlights .thirds p {
    font-size: 16px;
    color: var(--txtAdmin);
    margin: 0 0 8px 0;
}
.highlights .thirds p.stat {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
}
.highlights .thirds p.stat span {
    font-weight: 500;
    color: var(--txt);
    font-size: 19px;
}
.highlights .thirds.sml p {  margin-bottom: 5px; }
.highlights .thirds.sml h5.stat { font-size: 32px; }

.highlights .thirds button {
    border-radius: 32px;
    margin-top: 15px;
    font-weight: 300;
    font-size: 14px;
    padding: 8px 22px;
}

.hex-icon {
    background: rgb(0, 175, 145);
    width: 74px;
    height: 44px;
    padding: 6px;
    box-sizing: border-box;
    position: relative;
    margin: 32px auto;
}
.hex-icon:before {
    content: '';
    width: 70px;
    border: 22px rgb(0, 175, 145) solid;
    border-top: 22px solid rgba(0, 0, 0, 0);
    border-left: 37px solid rgba(0, 0, 0, 0) !important;
    border-right: 37px solid rgba(0, 0, 0, 0);
    position: absolute;
    top: -44px;
    left: 0;
    box-sizing: border-box;
}
.hex-icon:after {
    content: '';
    width: 70px;
    border: 22px rgb(0, 175, 145) solid;
    border-bottom: 22px solid rgba(0, 0, 0, 0);
    border-left: 37px solid rgba(0,0,0,0);
    border-right: 37px solid rgba(0, 0, 0, 0);
    position: absolute;
    bottom: -44px;
    left: 0;
    box-sizing: border-box;
}
.users .hex-icon { background-color: rgb(255, 207, 87); }
.users .hex-icon:before { border-bottom-color: rgb(255, 207, 87); }
.users .hex-icon:after { border-top-color: rgb(255, 207, 87); }
.assessments .hex-icon { background-color: rgb(109, 117, 255); }
.assessments .hex-icon:before { border-bottom-color: rgb(109, 117, 255); }
.assessments .hex-icon:after { border-top-color: rgb(109, 117, 255); }
.hex-icon:before {
    border-top: 22px solid rgba(0, 0, 0, 0) !important;
    border-left: 37px solid rgba(0, 0, 0, 0) !important;
    border-right: 37px solid rgba(0, 0, 0, 0) !important;
}
.hex-icon img {
    height: 30px;
    width: auto;
}

ul.autocomplete {
    border: 1px solid #83C7B5;
    border-top-width: 0;
}

ul.autocomplete a{
    border-bottom: none;
    padding: 8px 22px;
    transition: 0.2s;
    display: block;
}
ul.autocomplete a:hover {
    background: #F2F6F5;
}

ul.autocomplete a.autocomplete-create{
    opacity: 0.5;
    font-style: italic;
}

ul.autocomplete a.autocomplete-create:hover{
    opacity: 0.8;
}

/* Preloaders */
.loader {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
    margin: 20px auto 12px auto;
    display: block;
}
.loader.mtop-big { margin-top: 40vh; }
.loader:before,
.loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
}

.loader:before {
    animation: ball1 1s infinite;
    background-color: var(--bgDarkerBlueGrey);
    box-shadow: 30px 0 0 var(--grn);
    margin-bottom: 10px;
}

.loader:after {
    animation: ball2 1s infinite;
    background-color: var(--grn);
    box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) scale(0.8)
    }

    50% {
        transform: rotate(360deg) scale(1.2)
    }

    100% {
        transform: rotate(720deg) scale(0.8)
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 var(--grn);
    }

    50% {
        box-shadow: 0 0 0 var(--grn);
        margin-bottom: 0;
        transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 var(--grn);
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 var(--bgDarkerBlueGrey);
    }

    50% {
        box-shadow: 0 0 0 var(--bgDarkerBlueGrey);
        margin-top: -20px;
        transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 var(--bgDarkerBlueGrey);
        margin-top: 0;
    }
}

/* User settings header */
.usr-settings { 
    width: 50%; 
    min-width: 620px;
    margin: 0 auto 32px auto;
    padding: 25px 32px;
    background: #fff;
    border-radius: 18px;
    box-shadow: 1px 1px 20px var(--shadow);
    box-sizing: border-box;
}
.usr-settings-head {
    display: none;
}

/* Assessment room / Panolens */
.assessment-header {
    position: fixed;
    top: 20px;
    left: 0;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
}
.assessment-header h2 {
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.75);
}
.assessment-header .lght h2 {
    color: #000;
    text-shadow: none;
}
.assessment-header .right-header {
    float: right;
    text-align: right;
    padding-top: 16px;
}
.assessment-header .right-header .timer, .assessment-header .right-header a.test-btn {
    float: right;
    margin-left: 24px;
    background: var(--bgGrey);
    border: 1px solid var(--orange);
    padding: 8px 24px 8px 42px;
    border-radius: 50px;
}
.assessment-header .right-header .timer p,
.assessment-header .right-header a.test-btn {
    font-size: 14px;
    margin: 0;
    line-height: 14px;
    color: var(--txt);
}
.assessment-header .right-header a.test-btn:hover {
    opacity: 0.75;
}
.assessment-header .right-header .timer {
    background: #fff url('./../img/bpca/timer.png') left 16px center / 12px auto no-repeat; min-width: 40px;
}
.assessment-header .right-header a.test-btn.sitemap-btn {
    background: #fff url('./../img/bpca/compass.png') left 12px center / 16px auto no-repeat;
}
.assessment-header .right-header a.test-btn.camera {
    background: #fff url('./../img/bpca/camera.svg') left 14px center / 18px auto no-repeat;
}
.assessment-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.25);
    overflow: auto;
}
.assessment-footer button {
    margin: 15px 0 12px 0;
    border: 1px solid var(--grn);
    float: right;
}
.assessment-footer button:hover {
    background: var(--txtWhite);
    color: var(--grn);
    opacity: 1;
}
#roomView {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: #000;
}
.hotspot-cover {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--shadow);
    z-index: 2;
}
.hotspot-question {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 420px;
    background: var(--grn);
    box-sizing: border-box;
    z-index: 3;
    padding: 32px;
    color: var(--txtWhite);
    overflow-y: auto;
    overflow-x: hidden;
}
.hotspot-question .logo-sxn {
    width: 100%;
    margin: 0 0 44px 0;
}
.hotspot-question .logo-sxn img{
    width: 64px;
}
.hotspot-question h5 {
    font-size: 18px;
    margin: 0;
}
.hotspot-question p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 16px;
}
.hotspot-img {
    width: 800px;
    height: 537px;
    position: fixed;
    left: 50%;
    margin-left: -190px;
    top: 50%;
    margin-top: -268px;
    background: var(--bgGrey);
    box-shadow: 1px 1px 15px var(--shadow);
    padding: 5px;
    box-sizing: border-box;
}
.hotspot-cover #dropImg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.hotspot-cover .close-btn {
    float: right;
    font-size: 0;
    padding: 0;
    width: 42px;
    height: 42px;
    background: url('./../img/bpca/cross.svg') center center / 100% 100% no-repeat;
    margin: 0;
}
.hotspot-cover .hotspot-question .close-btn {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 12px;
}

.select-opener {
    background: var(--bgGrey);
    border-radius: 0;
    width: 100%;
    border: 1px solid var(--borderDarkGrey);
    margin: 0;
    text-align: left;
    padding: 12px 15px;
    color: var(--txt);
    margin-bottom: 32px;
    position: relative;
}
.select-opener::after {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 12px;
    height: 9px;
    margin-top: -5px;
    transform: rotate(180deg);
    content: "";
    background: url('./../img/bpca/select-arrow.svg') center center / 100% 100% no-repeat;
}
.select-opener.open::after {
    transform: rotate(0deg);
    margin-top: -3px;
}
.select-opener:hover {
    background: var(--bgGrey);
    color: var(--txtFaded);
}
ul.pseudo-select {
    background: var(--bgGrey);
    border: 1px solid var(--borderDarkGrey);
    border-top: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 32px;
    margin-top: -32px;
}
ul.pseudo-select li.option-select {
    padding: 0 0 0 22px;
    margin: 0;
}
ul.pseudo-select li button {
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    color: var(--txt);
    padding: 12px 15px;
}
ul.pseudo-select li span.selectable {
    display: block;
    padding-left: 52px;
    background: url('./../img/bpca/pin-faded.png') left 12px top 8px / 24px 31px no-repeat;
    transition:none;
    position: relative;
    color: var(--txt);
    font-size: 16px;
    font-weight: 500;
}
ul.pseudo-select > li > span.selectable {
    padding: 8px 15px 8px 52px;
}
ul.pseudo-select li span.selectable.selected {
    /* background: url('./../img/bpca/cb-checked.svg') 12px 15px / 16px auto no-repeat; */
}
ul.pseudo-select li.pseudo-radio button {
    padding-left: 40px;
    background: url('./../img/bpca/radio-unselected.svg') 12px 15px / 16px auto no-repeat;
    transition: none;
}
ul.pseudo-select li.pseudo-radio.selected button {
    background: url('./../img/bpca/radio-selected.svg') 12px 15px / 16px auto no-repeat;
}
ul.pseudo-select li button h6, ul.pseudo-select li span.selectable h6 {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
ul.pseudo-select li span.selectable h6 {
    font-weight: 300;
    font-size: 14px;
}
ul.pseudo-select li button p {
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    opacity: 0.5;
}
ul.pseudo-select li button.option-select.opener {
    position: relative;
    width: 100%;
}
ul.pseudo-select li button.option-select.opener::after {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 12px;
    height: 9px;
    margin-top: -5px;
    transform: rotate(180deg);
    content: "";
    background: url('./../img/bpca/select-arrow.svg') center center / 100% 100% no-repeat;
}
ul.pseudo-select li button.option-select.opener.open::after {
    transform: rotate(0deg);
    margin-top: -3px;
}
ul.pseudo-select li ul span.selectable.selected, ul.pseudo-select li ul span.selectable {
    padding: 0 15px 14px 96px;
    background-position: 58px 8px;
    background: url('./../img/bpca/pin-faded.png') left 56px top / 24px 31px no-repeat;
}
.pin {
    position: absolute;
    border: none;
    width: 24px;
    height: 31px;
    font-size: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
    cursor: move;
}
.pin.pin-big {
    width: 48px;
    height: 62px;
}
.pin button.pin-delete {
    display: none;
}
.pin.pin-big button.pin-delete {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 0;
    background: transparent;
    cursor: move;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

.pin.pin-big .pin-remove-lb {
    background: var(--bgGrey);
    border: 1px solid var(--grn);
    width: 180px;
    padding: 12px 15px;
    position: absolute;
    top: 40px; 
    right: 48px;
    border-radius: 6px;
    z-index: 2;
}
.pin.pin-big .pin-remove-lb h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin: 0 0 4px 0;
    padding: 0;
}
.pin.pin-big .pin-remove-lb p {
    font-size: 13px;
    font-weight: 300;
    margin: 0 0 8px 0;
    line-height: 18px;
}
.pin.pin-big .pin-remove-lb button {
    font-size: 13px;
    font-weight: 300;
    margin: 8px 5px 0 0;
    display: inline-block;
    padding: 6px 18px;
}
.pin.pin-big .pin-remove-lb button.btn-cancel {
    background: var(--bgDarkerBlueGrey);
    color: var(--txt);
}

ul.pseudo-select li ul {
    list-style: none;
}
ul.pseudo-select li ul h6 {
    font-size: 14px;
    padding: 0 0 0 22px;
}
ul.pseudo-select li ul li ul h6 {
    font-weight: 300;
}
ul.pseudo-select li button.option-select.with-info {
    display: block;
    padding-left: 0;
    box-sizing: border-box;
    width: 85%;
}
ul.pseudo-select li button.option-select.with-info h6 {
    padding-left: 4px;
}
ul.pseudo-select li button.info {
    width: 15%;
    height: 32px;
    float: left;
    font-size: 0;
    background: url('./../img/bpca/info.svg') right 12px center / 26px 26px no-repeat;
    margin: 5px 0 0 0;
    box-sizing: border-box;
}

.label-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    z-index: 5;
}
.label-cover .label {
    width: 680px;
    height: 450px;
    background: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
    margin-top: -225px;
    margin-left: -340px;
    padding: 18px 24px;
    box-sizing: border-box;
    overflow: auto;
}
.label-cover .label img {
    width: 100%;
}
.close-btn.dark {
    background-image: url('./../img/bpca/cross-dark.svg');
    top: 8px;
    right: 12px;
    margin: 0;
    position: absolute;
    z-index: 1;
}

.stuff {
    background: red;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 250px;
    left: 250px;
}

/* Assessment siteplans */
.siteplan-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--bgGrey);
    z-index: 1;
}
.siteplan-wrap .siteplan {
    margin: 12vh auto 0 auto;
    position: relative;
}
.siteplan-wrap .siteplan.practice {
    height: 680px;
    width: 612px;
    background: url('./../img/bpca/practice-sitemap.svg') center center / auto 100% no-repeat;
}
.siteplan-wrap .siteplan.real {
    height: 680px;
    width: 1250px;
    background: url('./../img/bpca/assessment-sitemap.svg') center center / auto 100% no-repeat;
}
.siteplan-wrap .siteplan .siteplan-pin {
    width: 48px;
    height: 62px;
    background: url('./../img/bpca/empty-pin.png') center center / 100% 100% no-repeat;
    position: absolute;
}
.siteplan-wrap .siteplan .siteplan-pin > a {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    text-decoration: none;
    border: none;
    z-index: 1;
}
.siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 95px; left: 412px; }
.siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 95px; left: 125px; }
.siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 310px; left: 135px; }
.siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 305px; left: 412px; }
.siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 495px; left: 235px; }

.siteplan-wrap .siteplan .siteplan-pin.location-11 { top: 350px; left: 350px; }
.siteplan-wrap .siteplan .siteplan-pin.location-12 { top: 542px; left: 370px; }
.siteplan-wrap .siteplan .siteplan-pin.location-13 { top: 495px; left: 240px; }
.siteplan-wrap .siteplan .siteplan-pin.location-14 { top: 380px; left: 182px; }
.siteplan-wrap .siteplan .siteplan-pin.location-15 { top: 190px; left: 182px; }
.siteplan-wrap .siteplan .siteplan-pin.location-16 { top: 68px; left: 182px; }
.siteplan-wrap .siteplan .siteplan-pin.location-17 { top: 68px; left: 270px; }
.siteplan-wrap .siteplan .siteplan-pin.location-18 { top: 75px; left: 360px; }
.siteplan-wrap .siteplan .siteplan-pin.location-19 { top: 170px; left: 350px; }

.siteplan-wrap .siteplan .siteplan-pin.location-32 { top: 257px; left: 585px; }
.siteplan-wrap .siteplan .siteplan-pin.location-33 { top: 120px; left: 535px; }
.siteplan-wrap .siteplan .siteplan-pin.location-35 { top: 95px; left: 680px; }
.siteplan-wrap .siteplan .siteplan-pin.location-34 { top: 85px; left: 780px; }
.siteplan-wrap .siteplan .siteplan-pin.location-36 { top: 450px; left: 655px; }
.siteplan-wrap .siteplan .siteplan-pin.location-37 { top: 450px; left: 740px; }
.siteplan-wrap .siteplan .siteplan-pin.location-38 { top: 450px; left: 840px; }
.siteplan-wrap .siteplan .siteplan-pin.location-39 { top: 450px; left: 935px; }
.siteplan-wrap .siteplan .siteplan-pin.location-40 { top: 490px; left: 1014px; }
.siteplan-wrap .siteplan .siteplan-pin.location-41 { top: 380px; left: 1102px; }

.siteplan-wrap .siteplan .siteplan-pin img.pin-icon {
    max-width: auto;
    width: auto;
    max-height: 24px;
    position:relative;
    margin: 12px auto;
    display: block;
    border-radius: 0;
}
.siteplan-wrap .siteplan .siteplan-pin img.pin-icon.bristles,
.siteplan-wrap .siteplan .siteplan-pin img.pin-icon.rat-trap-uncovered,
.siteplan-wrap .siteplan .siteplan-pin img.pin-icon.mouse-trap-uncovered {  
    max-height: auto;
    max-width: 28px;
    margin-top: 16px;
}
.siteplan-wrap .siteplan .siteplan-pin img.pin-icon.bristles { margin-top: 17px; }
.siteplan-wrap .siteplan .siteplan-pin img.pin-icon.mouse-trap-uncovered { margin-top: 12px; }

.preloading-imgs {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
}

/* Lightbox */
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    z-index: 1;
}
.cover.dark { background: rgba(0, 0, 0, 0.5); z-index: 5 }
.cover .hover-box {
    width: 680px;
    max-height: 45vh;
    background: #fff;
    margin: 25vh auto;
    padding: 28px 36px;
    border-radius: 12px;
    box-shadow: 1px 1px 15px var(--shadow);
    overflow: auto;
}
.cover .hover-box a.close-lnk {
    cursor: pointer;
    font-size: 0;
    border-bottom: none;
    width: 28px;
    height: 28px;
    background: url('./../img/close-icon.svg') center center / 100% auto no-repeat;
    float: right;
    display: block;
    margin: -12px -15px 12px 0;
}
.cover .hover-box h2 {
    font-size: 28px;
    padding-bottom: 5px;
}
.cover .hover-box h2:after {
    height: 4px;
    width: 60px;
    background: var(--grn);
    display: block;
    content: "";
    margin-top: 3px;
}
.cover .hover-box p {
    margin-bottom: 12px;
}
p.lnk-wrap {
    word-wrap: break-word;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
}

/* Form elements */
ul.columns {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
}
ul.columns li {
    float: left;
    width: 50%;
    padding: 0 24px 8px 0;
    box-sizing: border-box;
    position: relative;
}
.input.checkbox {
    position: relative;
}
.input.checkbox input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 2px;
    margin-right: 18px;
    margin-top: 10px;
    opacity: 0;
    z-index: 1;
} 
.input.checkbox label {
    display: inline;
    position: relative;
    padding-left: 30px;
    padding-top: 5px;
}
.input.checkbox label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 8px;
    left: 0;
    background: url('./../img/bpca/cb-sprite.svg') center top  / 100% auto no-repeat;
}
.input.checkbox input[type=checkbox]:checked + label::after {
    background-position: center top -40px;
}

/* Password strength meter */
#pwForm { position: relative; }
.strength-meter {
    /* display: none; */
    position: absolute;
    top: -280px;
    left: 120px;
    width: 420px;
    background: #fff;
    padding: 18px 25px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    border-radius: 12px;
    font-size: 14px;
    z-index: 1;
    display: none;
}
.strength-meter.display {
    display: block;
}
.strength-meter p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0
}
.strength-meter ul {
    list-style: none;
}
.strength-meter ul li {
    padding-left: 28px;
    line-height: 16px;
    margin: 14px 0;
    background: url('./../img/cross.svg') center left / 16px auto no-repeat;
}
.strength-meter ul li.ticked {
    background: url('./../img/tick.svg') center left / 16px auto no-repeat;
}

/* Filter */
.filter {
    background-image: linear-gradient(to right, rgb(245, 246, 252), rgb(199, 218, 252));
    padding: 18px 22px;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 32px;
    overflow: auto;
}
.filter .input {
    display: block; 
    float: left;
    width: 25%;
    margin: 0 5% 0 0;
}
.filter .input label {
    margin-top: 0;
}
.filter .input input, .filter .select select {
    font-size: 16px;
    font-weight: 300;
    padding: 10px 18px;
}
.filter button {
    padding: 11px 32px;
    font-size: 16px;
}

hr.password-divider{
    border:1px dashed #CBE2DC;
    margin:30px 0 20px 0;
}

/* File upload styling */
.file-upload {
    width: 100%;
    height: 90px;
    border: 2px dotted #CBE2DC;
    box-sizing: border-box;
    position: relative;
    padding: 14px;
    cursor:pointer;
}
.file-upload:hover {
    border-width: 4px;
    padding: 12px;
}
.file-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.file-upload label {
    text-align: center;
    cursor: pointer;
    font-style: italic;
}

.mobile, .mobile-menu { display: none; }

/* Slip into the responsive styling */
@media only screen and (max-width: 1520px) {

    

}

@media only screen and (max-width: 1320px) {

    .pin-show button { width: 42px; height: 42px; }
    
}

@media only screen and (max-width: 1280px) {

    .big-hide { display: none !important; }

}
@media only screen and (max-width: 1252px) {
    .pin-show { text-align: center; }
    .pin-show .img { width: 100%; }
    .pin-show button, .pin-show button.nxt { margin: 0 5px; float: none; display: inline-block; text-align: center; }

    .hotspot-question { width: 360px; }
    ul.pseudo-select li button.info { width: 18% }
    ul.pseudo-select li button.option-select.with-info { width: 82% }
    .hotspot-img { margin-left: -218px; }
}

@media only screen and (max-width: 1160px) {

    .hotspot-img { width: 700px; height: 470px; margin-left: -168px; margin-top: -232px; }

}

@media only screen and (max-width: 1100px) {

    .whole .sidebar { width: 100%; height: auto; float: none; position: relative; padding: 18px 32px; }
    .whole .sidebar .logo-sxn { float: left; margin-bottom: 0; padding-bottom: 0; }
    .whole .sidebar nav { float: right; padding: 4px 0 0 0; }
    .whole .sidebar nav ul { display: inline-block; }
    .whole .sidebar nav ul.btm { position: relative; bottom: auto; }
    .whole .sidebar nav ul li { display: inline-block; position: relative; width: auto; margin-left: 5px; }
    header.sidebar nav ul li.current, header.sidebar nav ul li:hover { 
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        border-radius: 4px;
    }

    .admin-container { padding: 0; margin-top: 32px; }


}

@media only screen and (max-width: 1054px) {

    .hotspot-img { width: 600px; height: 404px; margin-left: -118px; margin-top: -202px; }

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 960px) {

    .whole .sidebar .mobile-menu.admin { 
        display: inline-block; 
        float: right;
        margin: 12px 0;
        padding: 0;
        border-top: var(--txt) 5px solid;
        border-bottom: var(--txt) 5px solid;
        line-height: 22px;
        text-transform: uppercase;
    }
    .whole .sidebar nav.admin-menu ul { display: none; }
    .whole .sidebar nav.admin-menu ul.open { display: block; clear: right; }
    .whole .sidebar nav.admin-menu ul.open li { display: block; }

    .hotspot-img { width: 500px; height: 336px; margin-left: -68px; margin-top: -167px; }

    a.sitemap-btn { display: none; }

}

@media only screen and (max-width: 860px) {

    .mobile { display: block; }

    .login .sidebar { width: 5%; }
    .login .sidebar img, .login .sidebar h1, .login .sidebar p { display: none; }

    .login .core { padding: 0 0 0 5%; background-position: top left; overflow: auto; }
    .login .login-content { width: 80%; max-width: 380px; margin: 0 auto; padding: 80px 0; }
    .login .login-content .logo-sxn { width: 194px; margin: 0 auto 72px auto; }

    .intro .width-6 { width: 90%; }
    .steps { width: 90%; max-width: 480px; }
    .steps .step, .steps .step+.step { width: 100%; float: none; margin: 0 0 32px 0; }
    .logo-sxn { margin-bottom: 0; }
    
    .cover .hover-box { max-width: 680px; width: 90%; max-height: 80vh; margin: 10vh auto; box-sizing: border-box; overflow: auto; }

    .pseudo-table .cell { width: 30%; }

    .filter .input { width: 100%; float: none; margin: 0 0 16px 0; }
    .filter button { margin: 0; }

    .med-hide { display: none !important; }

    .hotspot-img { 
        position: fixed;
        width: 100%; 
        height: calc(((100vw - 10px) * 0.667) + 10px);
        margin: 0;
        top: calc(100vh - (((100vw - 10px) * 0.667) + 10px));
        left: 0;
    }
    .hotspot-question { 
        width: 100%;
        position: fixed;
        height: calc(100vh - (((100vw - 10px) * 0.667) + 10px));
        top: 0;
        left: 0;
    }

}

@media only screen and (max-width: 740px) {

    header .logo-sxn.width-3.left { width: auto;}
    header .width-9 { width: auto; }
    header nav { margin: 0; padding: 0; }
    header nav ul { display: none; }
    header nav ul.open { display: block; }
    header nav .mobile-menu { 
        display: inline-block; 
        margin: 12px 0; 
        padding: 0;
        border-top: var(--txt) 5px solid;
        border-bottom: var(--txt) 5px solid;
        line-height: 22px;
        text-transform: uppercase;
    }
    header nav ul li { display: block; }
    header nav ul li a { padding: 15px 0 15px 22px; }

    .usr-settings { min-width: 90%; }

    .pseudo-table .cell { width: 40%; }
    .sml-hide { display: none !important; }

}

@media only screen and (max-width: 640px) {

    .highlights { width: 100%; margin: 0 auto 42px auto; max-width: 480px; }
    .highlights .thirds { width: 100%; margin-bottom: 12px; text-align: left; min-height: 0; }
    .highlights .thirds h6 { margin-top: 0; float: left; margin-right: 12px; }
    .highlights .thirds p.stat { margin-left: 96px; }
    .highlights .thirds .hex-icon { float: left; margin: 15px 22px 0 0; }
    .highlights .thirds .hex-icon img { display: block; margin-left: auto; margin-right: auto; }
    .highlights .thirds a.btn.curved { margin: 0; }

}

/* Practice sitemap responsiveness */
@media only screen and (max-height: 870px) {
    
    .siteplan-wrap .siteplan.practice {
        height: 580px;
        width: 522px;
    }
    .siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 75px; left: 345px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 75px; left: 105px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 255px; left: 105px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 250px; left: 345px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 445px; left: 200px; }

}
@media only screen and (max-width: 800px) {
    
    .siteplan-wrap .siteplan.practice {
        height: 580px;
        width: 522px;
    }
    .siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 75px; left: 345px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 75px; left: 105px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 255px; left: 105px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 250px; left: 345px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 445px; left: 200px; }

}
@media only screen and (max-height: 760px) {
    
    .siteplan-wrap .siteplan.practice {
        height: 460px;
        width: 414px;
    }
    .siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 45px; left: 270px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 45px; left: 75px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 192px; left: 75px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 180px; left: 270px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 340px; left: 152px; }

}
@media only screen and (max-width: 600px) {
    
    .siteplan-wrap .siteplan.practice {
        height: 460px;
        width: 414px;
    }
    .siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 45px; left: 270px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 45px; left: 75px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 192px; left: 75px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 180px; left: 270px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 340px; left: 152px; }

}

@media only screen and (max-height: 760px) {
    
    .siteplan-wrap .siteplan.practice {
        height: 320px;
        width: 288px;
        margin-top: 85px;
    }
    .siteplan-wrap .siteplan .siteplan-pin.location-1 { top: 14px; left: 178px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-2 { top: 14px; left: 75px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-4 { top: 110px; left: 45px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-3 { top: 105px; left: 178px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-5 { top: 215px; left: 100px; }

}

/* Real test sitemap responsive styles */
@media only screen and (max-width: 1345px) {
    .siteplan-wrap .siteplan.real {
        height: 544px;
        width: 1000px;
    }

    .siteplan-wrap .siteplan .siteplan-pin.location-11 { top: 290px; left: 275px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-12 { top: 422px; left: 290px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-13 { top: 385px; left: 192px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-14 { top: 300px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-15 { top: 170px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-16 { top: 42px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-17 { top: 42px; left: 215px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-18 { top: 48px; left: 295px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-19 { top: 130px; left: 275px; }

    .siteplan-wrap .siteplan .siteplan-pin.location-32 { top: 195px; left: 465px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-33 { top: 95px; left: 425px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-35 { top: 70px; left: 545px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-34 { top: 60px; left: 605px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-36 { top: 348px; left: 525px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-37 { top: 348px; left: 610px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-38 { top: 348px; left: 680px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-39 { top: 348px; left: 760px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-40 { top: 380px; left: 808px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-41 { top: 300px; left: 875px; }

}
@media only screen and (max-height: 845px) {
    .siteplan-wrap .siteplan.real {
        height: 544px;
        width: 1000px;
    }

    .siteplan-wrap .siteplan .siteplan-pin.location-11 { top: 290px; left: 275px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-12 { top: 422px; left: 290px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-13 { top: 385px; left: 192px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-14 { top: 300px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-15 { top: 170px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-16 { top: 42px; left: 142px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-17 { top: 42px; left: 215px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-18 { top: 48px; left: 295px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-19 { top: 130px; left: 275px; }

    .siteplan-wrap .siteplan .siteplan-pin.location-32 { top: 195px; left: 465px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-33 { top: 95px; left: 425px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-35 { top: 70px; left: 545px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-34 { top: 60px; left: 605px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-36 { top: 348px; left: 525px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-37 { top: 348px; left: 610px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-38 { top: 348px; left: 680px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-39 { top: 348px; left: 760px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-40 { top: 380px; left: 808px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-41 { top: 300px; left: 875px; }

}

@media only screen and (max-width: 1068px) {
    .siteplan-wrap .siteplan.real {
        height: 408px;
        width: 750px;
    }

    .siteplan-wrap .siteplan .siteplan-pin.location-11 { top: 300px; left: 200px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-12 { top: 272px; left: 135px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-13 { top: 205px; left: 100px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-14 { top: 100px; left: 100px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-15 { top: 18px; left: 100px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-16 { top: 18px; left: 162px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-17 { top: 21px; left: 215px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-18 { top: 80px; left: 200px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-19 { top: 180px; left: 200px; }

    .siteplan-wrap .siteplan .siteplan-pin.location-32 { top: 130px; left: 342px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-33 { top: 60px; left: 312px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-35 { top: 35px; left: 400px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-34 { top: 25px; left: 445px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-36 { top: 245px; left: 380px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-37 { top: 245px; left: 440px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-38 { top: 245px; left: 500px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-39 { top: 245px; left: 555px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-40 { top: 270px; left: 598px; }
    .siteplan-wrap .siteplan .siteplan-pin.location-41 { top: 210px; left: 650px; }

}
